<template>
  <v-row class="justify-center">
    <v-card
      color="bgMinsal"
      elevation="0"
      v-for="item in info"
      :key="item.id"
      class="mx-11 mt-8"
    >
      <v-card-title class="d-flex flex-column justify-center">
        <p class="text-h4">{{ item.total }}</p>
        <p class="mt-n6 subtitle-1 text-center text-wrap">{{ item.nombre }}</p>
      </v-card-title>
      <v-progress-linear
        :color="item.color_estado"
        rounded
        value="100"
        class="mt-n8"
      />
    </v-card> 
  </v-row>
</template>

<script>
export default {
  name: "StatsComponent",
  props: {
    info: {
      type: Array,
      required: true,
    },
  },
  methods:{}
};
</script>
<style lang="scss" scoped>
.v-application .subtitle-1{
  font-family: "MuseoSans", sans-serif !important;
}
</style>