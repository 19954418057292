<template>
  <section>
    <v-row>
      <v-col cols="12" sm="6">
        <v-breadcrumbs large class="px-0" :items="breadcrumbs" divider="/" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p class="text-h5 secondary--text">{{ nombre }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" class="pt-0">
        <StatsComponent :info="info" />
      </v-col>
      <v-col cols="12" sm="5"></v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          label="Buscar proveedor"
          append-icon="mdi-magnify"
          v-model="proveedor"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <DataTable
          :headers="headers"
          :items="items"
          :show_actions="true"
          :actions="actions"
          @evaluacionBtn="evaluacionBtn"
          v-models:select="lista_ofertas.per_page"
          v-models:pagina="lista_ofertas.page"
          :total_registros="lista_ofertas.total_rows"
          @paginar="paginar"
        >
          <template v-slot:[`item.evaluacion_legal`]="{ item }">
            <v-icon v-if="item.evaluacion_legal === true" color="success">
              mdi-check-circle</v-icon
            >
            <v-icon v-if="item.evaluacion_legal === false" color="btnRed">
              mdi-cancel</v-icon
            >
            <span v-if="item.evaluacion_legal === null">---</span>
          </template>

          <template v-slot:[`item.porcentaje_otorgado`]="{ item }">
            <div class="pt-1 pb-0 mb-0">
              <v-text-field
                dense
                outlined
                hide-details="true"
                @keypress="isNumber($event)"
                v-model.lazy="item.porcentaje_otorgado"
                :disabled="proveedorBloqueado(item)"
              />
            </div>
          </template>

          <template v-slot:[`item.nombre_estado_participacion`]="{ item }">
            <v-chip
              class="d-flex justify-center"
              small
              :color="getColor(item)"
              :dark="isDark(item)"
              label
            >
              {{ item.nombre_estado_participacion }}
            </v-chip>
          </template>

          <template v-slot:[`item.total`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.total)
            }}
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          outlined
          class="mt-5 mr-5"
          color="secondary"
          :block="buttonBlock"
          @click="$router.go(-1)"
        >
          Volver
        </v-btn>
        <v-btn
          color="secondary "
          class="mt-5"
          :dark="this.info[0].total_numerico === 100"
          :block="buttonBlock"
          :disabled="this.info[0].total_numerico !== 100"
          @click="actualizarPorcentajeOtorgado"
        >
          Guardar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import StatsComponent from "@/components/StatsComponent.vue";
import DataTable from "@/components/DataTableComponent.vue";
const colores = { 4: "#ffeba6", 5: "#57cc99" };
import { mapActions, mapState } from "vuex";

export default {
  name: "ofertasRealizadasComponent",
  props: {
    id: {
      type: Number,
      default: null
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Array,
      default: () => [],
    },
    nombre: {
      type: String,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Array,
      default: () => [],
    },
    disabledTextFields: {
      type: Boolean,
      default: false,
    },
    headerRes: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    StatsComponent,
    DataTable,
  },
  data: () => ({
    proveedor: "",
    subproceso_name: null,
  }),
  methods: {
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.lista_ofertas.per_page = cantidad_por_pagina;
      this.lista_ofertas.page = pagina;

      const params = {
        nombre: this.proveedor.trim(),
        pagination: true,
        per_page: this.lista_ofertas.per_page,
        page: this.lista_ofertas.page
      }
      this.obtenerEvaluacionesUACI(this.id, params);
    },
    async obtenerEvaluacionesUACI(id, params = {}) {
      const response = await this.services.Paac.getEvaluacionUaciOferta(
        id,
        params
      );
      if (response) {
        // this.items = response?.data?.ofertas;
        this.$emit("update-items", response?.data?.ofertas);
        this.$store.commit(
          "procesoCompra/setPaginationOfertas",
          response.headers
        );
      }
    },
    evaluacionBtn(item) {
      this.$router.push({
        name: "evaluacion-uaci-ofertas",
        params: {
          id_proveedor_participacion_obs: item.id_proveedor_participacion_obs,
        },
      });
    },
    getColor(item) {
      const { id_estado_participacion: id } = item;
      return colores[id] || "bgMinsal";
    },
    isDark(item) {
      const { id_estado_participacion: id } = item;
      return id === 5;
    },
    proveedorBloqueado(item) {
      return !item.evaluacion_legal;
    },
    isNumber(evt) {
      const {
        keyCode,
        key,
        target: { value },
      } = evt;

      if (keyCode < 48 || keyCode > 57) {
        return evt.preventDefault();
      }
      const totalOtorgado = +this.info[0].total_numerico;

      if (totalOtorgado > 100) {
        return evt.preventDefault();
      }

      const inputSumado = +value || 0;
      const proximoTotal = +(value + key) || 0;
      const cantidadDisponible = totalOtorgado - inputSumado;

      if (cantidadDisponible + proximoTotal > 100) {
        return evt.preventDefault();
      }
    },

    async actualizarPorcentajeOtorgado() {
      if (this.info[0].total_numerico === 100) {
        const count = this.items.map(
          ({ id_proveedor_participacion_obs, porcentaje_otorgado }) =>
            this.services.Paac.actualizarSubProcesoObs(
              id_proveedor_participacion_obs,
              { porcentaje_otorgado: +porcentaje_otorgado || 0 }
            )
        );
        await Promise.all(count);
        this.$router.back(-1);
      }
    },
  },

  watch: {
    proveedor(newValue, oldValue) {
      this.$emit("buscarproveedor", newValue);
    },
  },

  computed: {
    buttonBlock() {
      return !!this.$vuetify.breakpoint.xs;
    },
    ...mapState("procesoCompra", ["lista_ofertas"]),
  },

  created() {
    this.subproceso_name = this.$refs.dataObs;
  },
};
</script>
