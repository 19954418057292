<template>
  <OfertaRealizada
  :id="parseInt($route.params.id_sub_proceso)"
   :breadcrumbs="breadcrumbs" :info="info" :nombre="infoObs.nombre_agrupacion" :headers="headers" :items="items" :disabledTextFields="true"
    :actions="actions" @buscarproveedor="buscarProveedor" :headerRes.sync="lista_ofertas" />
</template>

<script>
import OfertaRealizada from "../PaacUaci/components/OfertaRealizadaComponent.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "ofertasRealizadasView",

  components: {
    OfertaRealizada,
  },

  mounted() {
    this.obtenerEvaluacionesUACI(this.$route.params.id_sub_proceso);
    this.getInfoGeneral()
    this.getOfertas()
  },

  data: () => ({
    infoGeneral: {},
    headerRes: {},
    itemsObs: {},
    infoObs: {},
    info: [
      {
        total: "0%",
        nombre: "Otorgado",
        color_estado: "success",
      },
    ],
    headers: [
      {
        text: "Proveedor",
        align: "start",
        width: "25%",
        sortable: true,
        value: "nombre_comercial",
      },
      {
        text: "Monto ofertado",
        align: "end",
        sortable: true,
        value: "total",
      },
      {
        text: "Evaluación Financiera",
        align: "end",
        sortable: true,
        value: "evaluacion_financiera",
      },
      {
        text: "Evaluación Técnica",
        align: "end",
        sortable: true,
        value: "evaluacion_tecnica",
      },
      {
        text: "Evaluación Legal",
        align: "end",
        sortable: true,
        value: "evaluacion_legal",
      },
      {
        text: "% Otorgado",
        width: "10%",
        align: "center",
        sortable: true,
        value: "porcentaje_otorgado",
      },
      {
        text: "Estado",
        align: "center",
        sortable: true,
        value: "nombre_estado_participacion",
      },

      {
        text: "Acciones",
        align: "center",
        sortable: true,
        value: "actions",
      },
    ],
    items: [],
    actions: [
      {
        tooltip: {
          text: "Evaluación",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-clipboard-check-outline",
        },
        eventName: "evaluacionBtn",
      },
    ],
  }),

  computed: {
    ...mapState("procesoCompra", [
      "lista_ofertas",
    ]),
    buttonBlock() {
      return !!this.$vuetify.breakpoint.xs;
    },
    breadcrumbs(){
      return [
      {
        text: "Procesos",
        disabled: false,
        href: "#",
      },
      {
        text: this.infoGeneral.codigo_proceso,
        disabled: false,
        href: "#",
      },
      {
        text: "Evaluación",
        disabled: false,
        href: "#",
      },
      {
        text: `${this.itemsObs.codigo} - ${this.itemsObs.nombre}`,
        disabled: false,
        href: "#",
      },
      {
        text: this.infoObs.nombre_agrupacion,
        disabled: false,
        href: "#",
      },
      ]
    }
  },

  watch: {
    items: {
      handler(newValue) {
        if (Array.isArray(newValue)) {
          const total = newValue.reduce((accum, current) => {
            accum += +current.porcentaje_otorgado || 0;
            return accum;
          }, 0);

          this.info = [
            {
              total: `${total}%`,
              total_numerico: total,
              nombre: "Otorgado",
              color_estado: "success",
            },
          ];
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions("procesoCompra", [
      "getInfoGeneralProcesoCompra",
      "getOfertasProcesoCompra",
      "setPaginationOfertas",
    ]),
    async obtenerEvaluacionesUACI(id, params = {}) {
      const response = await this.services.Paac.getEvaluacionUaciOferta(id, params);
      if (response) {
        this.items = response?.data?.ofertas;
        this.$store.commit("procesoCompra/setPaginationOfertas", response.headers);
      }
    },
    buscarProveedor(nombre) {
      if (typeof nombre == "string") {
        const valor = nombre.trim();
        if (!!valor) {
          this.obtenerEvaluacionesUACI(this.$route.params.id_sub_proceso, { nombre: valor });
        } else {
          this.obtenerEvaluacionesUACI(this.$route.params.id_sub_proceso);
        }
      }
    },
    async getInfoGeneral(){
      const response = await this.getInfoGeneralProcesoCompra(this.$route.params.id_proceso)
      this.infoGeneral = response.data
    },
    async getOfertas() {
      let params = {
        pagination: true,
      };
      let idSubproceso = this.$route.params.id_proceso_obs;
      let response = await this.services.Paac.getOfertasDetalle(
        idSubproceso,
        params
      );
      this.infoObs = response.data.dataSubProcesos[0];
      this.itemsObs = response.data.dataObs;
    },
  },

};
</script>

<style>

</style>
